:root {
  --primary-color: #6d28d9;
  --table-header-bg: #9c2ba6;
  --table-header-color: #ffffff;
  --table-odd-row-bg: #e6e3ff;
  --table-even-row-bg: #ffffff;
  --phone-input-height: 47px;
  --phone-input-font-size: 16px;
  --react-select-height: 53px;
  --sidebar-icon-bg-color: "#2b2b2b";
  --sidebar-title-color: "#adadad";
  --sidebar-title-hover-color: "#ffffff";
  --sidebar-title-active-color: "#ffffff";
  --label-bg-color: "#fff";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}

/* These styles make the root element full-height */
#root {
  display: flex;
  height: 100%;
}

.sbContentHolder-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.3);
}

.PhoneInputInput {
  height: var(--phone-input-height) !important;
  margin-right: 5px;
  font-size: var(--phone-input-font-size);
  font-weight: medium;
  border: none;
  outline: none;
}

.select__control {
  border: none !important;
  outline: none !important;
  min-height: var(--react-select-height) !important;
  margin-right: 2px;
}

.select__control--is-focused {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.rc-table table {
  border-collapse: collapse;
}
.rc-table-thead {
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
}
.rc-table-thead .rc-table-cell {
  background-color: var(--table-header-bg);
  color: white !important;
  padding: 16px 16px !important;
}

.rc-table-tbody .rc-table-row {
  background-color: var(--table-odd-row-bg);
}

.rc-table-tbody .rc-table-row:nth-of-type(even) {
  background-color: var(--table-even-row-bg);
}

.rc-table-tbody .rc-table-row:last-child {
  border-bottom: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-tbody .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-tbody .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-cell {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
}

.rc-pagination-item-active {
  border-color: var(--primary-color) !important;
}

.rc-pagination-item-active a {
  color: var(--primary-color) !important;
}

.Dropdown-control {
  width: 65px !important;
}

.pro-icon-wrapper {
  background-color: var(--sidebar-icon-bg-color) !important;
}

.pro-menu-item {
  color: var(--sidebar-title-color);
}

.pro-menu-item:hover {
  color: var(--sidebar-title-hover-color) !important;
}

.pro-menu-item.active {
  color: var(--sidebar-title-active-color) !important;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-main {
  flex-grow: 1;
}

.fc {
  max-width: 1100px;
  margin: 0 auto;
}

.jodit-container:not(.jodit_inline) {
  border: none !important;
  border-bottom-right-radius: 6px;
}

.jodit-placeholder {
  display: none !important;
}

.DayPickerInput-Overlay {
  z-index: 10001 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
#numberBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#numberBox::-webkit-inner-spin-button,
#numberBox::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#numberBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none;
  outline: none;
}

#numberBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}
#textBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#textBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

#textarea {
  border: none;
  outline: none;
  resize: none;
  padding-top: 10px;
  border: 1px solid #d9d9d9;
}

#textarea:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textarea:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.header-fixed {
  background-color: #292c2f;
  box-shadow: 0 1px 1px #ccc;
  padding: 20px 40px;
  height: 80px;
  color: #ffffff;
  box-sizing: border-box;
  top: -100px;

  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}

.header-fixed .header-limiter {
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
}

/*	The header placeholder. It is displayed when the header is fixed to the top of the
      browser window, in order to prevent the content of the page from jumping up. */

.header-fixed-placeholder {
  height: 80px;
  display: none;
}

/* Logo */

.header-fixed .header-limiter h1 {
  float: left;
  font: normal 28px Cookie, Arial, Helvetica, sans-serif;
  line-height: 40px;
  margin: 0;
}

.header-fixed .header-limiter h1 span {
  color: #5383d3;
}

/* The navigation links */

.header-fixed .header-limiter a {
  color: #ffffff;
  text-decoration: none;
}

.header-fixed .header-limiter nav {
  font: 16px Arial, Helvetica, sans-serif;
  line-height: 40px;
  float: right;
}

.header-fixed .header-limiter nav a {
  display: inline-block;
  padding: 0 5px;
  text-decoration: none;
  color: #ffffff;
  opacity: 0.9;
}

.header-fixed .header-limiter nav a:hover {
  opacity: 1;
}

.header-fixed .header-limiter nav a.selected {
  color: #608bd2;
  pointer-events: none;
  opacity: 1;
}

/* Fixed version of the header */

body.fixed .header-fixed {
  padding: 10px 40px;
  height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

body.fixed .header-fixed-placeholder {
  display: block;
}

body.fixed .header-fixed .header-limiter h1 {
  font-size: 24px;
  line-height: 30px;
}

body.fixed .header-fixed .header-limiter nav {
  line-height: 28px;
  font-size: 13px;
}

/* Making the header responsive */

@media all and (max-width: 600px) {
  .header-fixed {
    padding: 20px 0;
    height: 75px;
  }

  .header-fixed .header-limiter h1 {
    float: none;
    margin: -8px 0 10px;
    text-align: center;
    font-size: 24px;
    line-height: 1;
  }

  .header-fixed .header-limiter nav {
    line-height: 1;
    float: none;
  }

  .header-fixed .header-limiter nav a {
    font-size: 13px;
  }

  body.fixed .header-fixed {
    display: none;
  }
}
.Loki {
  max-width: 100%;
  margin: 0 auto;
}

.LokiSteps {
  width: 100%;
  position: relative;
  display: table;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  border-collapse: separate;
  margin-left: 0px;
  padding-left: 0;
  padding-top: 20px;
}

.Loki-Actions {
  display: block;
  padding: 20px 0;
}

.LokiSteps .LokiStep {
  display: table-cell;
  position: relative;
  float: none;
  padding: 0;
  width: 1%;
}

.LokiSteps .LokiStep .LokiStep-Icon {
  background-color: white;
  border-radius: 50%;
  border: 1px solid #ccc;
  color: #ccc;
  font-size: 24px;
  height: 64px;
  margin: 0 auto;
  position: relative;
  width: 64px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LokiSteps .LokiStep .LokiStep-Link.disabled {
  cursor: not-allowed;
}

.LokiSteps .LokiStep.LokiStep-Active .LokiStep-Icon {
  border-color: #75b2f5;
  color: #75b2f5;
}

.LokiSteps .LokiStep.LokiStep-Complete .LokiStep-Icon {
  border-color: #05ae0e;
  color: #05ae0e;
}

.LokiSteps .LokiStep:after {
  left: 50%;
  background-color: #ccc;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 100%;
  top: 35px;
}

.LokiSteps .LokiStep:last-child:after {
  display: none;
}
/*
       We are clearing the body's margin and padding, so that the header fits properly.
       We are also adding a height to demonstrate the scrolling behavior. You can remove
       these styles.
   */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.background-images-set {
  background-image: url(../src/assets/images/sprots-background-image.jfif);
  background-position-y: -60px;
  background-position-x: center;
  background-size: 1830px;
  background-repeat: no-repeat;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.sprots-logo {
  width: 90px;
}
.AiOutlineMinusCircle {
  padding: 5px !important;
  border: 1px solid rgba(255, 0, 0, 0.596) !important;
  background: rgba(255, 0, 0, 0.596) !important;
  color: white !important;
}
.AiOutlineMinusCircle:hover {
  background: white !important;
  color: black !important;
}
.back-button:hover {
  border: 1px solid rgba(255, 0, 0, 0.596) !important;
  background: rgba(255, 0, 0, 0.596) !important;
  color: white !important;
}
.submit-button {
  border: 1px solid green !important;
  color: #05ae0e !important;
}
.submit-button:hover {
  border: 1px solid green !important;
  background: green !important;
  color: white !important;
}

s.ant-table-thead .ant-table-cell {
  background-color: var(--table-header-bg);
  color: var(--table-header-color) !important;
}
.ant-table-tbody .ant-table-row {
  background-color: var(--table-odd-row-bg);
}
.ant-table-tbody .ant-table-row:nth-of-type(even) {
  background-color: var(--table-even-row-bg);
}
.ant-table-tbody .ant-table-row:last-child {
  border-bottom: 1px solid #d5d5d5;
}
.ant-table-thead .ant-table-cell {
  border-right: 1px solid #d5d5d5;
}
.ant-table-thead .ant-table-cell:last-child {
  border-right: 0px !important;
}
.ant-table-tbody .ant-table-cell {
  border-right: 1px solid #d5d5d5;
}
.ant-table-tbody .ant-table-cell:last-child {
  border-right: 0px !important;
}
.ant-table-tbody .ant-table-row .ant-table-cell-row-hover {
  background-color: #f0f0f0 !important;
}
.ant-table-cell {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #822797 !important;
  color: white !important;
  /* background: linear-gradient(99.9deg,#381d6d,#aa2dae) !; */
}
.layout-icons {
  width: 30px;
}
.add-cms-but {
  width: 100px;
  height: 150px;
}
.list-table {
  /* overflow: none !important; */
  padding: 20px;
}
.ant-table-content {
  overflow: scroll !important;
  overflow-y: hidden !important;
  border-radius: 0% !important;
}
.rc-table-content table > thead > tr:first-child th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px;
}
.rc-table-content table > thead > tr:first-child th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px;
  border-bottom: 0px solid;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px;
}
.pro-sidebar > .pro-sidebar-inner {
  background: #000000 !important;
}
.ant-modal-close {
  top: 31px !important;
  width: 0px !important;
  height: 0px !important;
  inset-inline-end: 60px !important;
}

.add-cms-list-but {
  margin: 10px !important;
  width: 90px !important;
  height: 40px !important ;
  font-weight: bold !important;
  background-color: #822797 !important;
  color: white !important;
}
.back-button2 {
  width: 90px !important;
  height: 40px !important ;
}
.delete-button:hover {
  border: 1px solid red !important;
  color: white !important;
  background-color: rgba(255, 0, 0, 0.459) !important;
}
.edit-button:hover {
  border: 1px solid green !important;
  color: white !important;
  background-color: rgba(0, 128, 0, 0.687) !important;
}
@media screen and (max-width: 2560px) and (min-width: 600px) {
  .btn_width {
    width: 250px;
  }
}
.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #183153;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border: none;
  text-align: center;
}

.button:after {
  content: " ";
  width: 0%;
  height: 100%;
  background: #aa2dae;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
  border-radius: 5px;
  border: 0px solid;
}

.button:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}

.button span {
  text-align: center;
  text-decoration: none;
  width: 100%;
  /* padding: 10px 10px; */
  width: 100px;
  height: 30px;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  /* letter-spacing: 0.3em; */
  z-index: 20;
  transition: all 0.3s ease-in-out;
  margin-top: 5px;
  margin-bottom: 5px;
}

.button:hover span {
  color: #fff;
  animation: scaleUp 0.3s ease-in-out;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 2560px) and (min-width: 600px) {
  .btn_width {
    width: 250px;
  }
}

.menu_icon {
  background-color: #3f86e9;
  border-radius: 30px;
  color: #ffffff;
}

.non_menu_icon:hover {
  background-color: #a9c3e9;
  border-radius: 25px;
  transition: 0.2s ease-in-out;
  /* color: white; */
}
.menu_item_name {
  color: #000;
  font-weight: bold;
}
.pro-icon-wrapper {
  background: linear-gradient(45deg, transparent, transparent) !important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background-color: transparent !important;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: var(--table-header-bg) !important;
  border-radius: 18px !important;
  color: white !important;
}
.rc-pagination-item-active a {
  color: white !important;
}

.pro-menu-item:hover {
  color: var(--sidebar-title-hover-color) !important;
}

.pro-menu-item.active {
  color: var(--sidebar-title-active-color) !important;
}

.admin-menu-list .ant-menu-light .ant-menu-item-selected {
  background-color: var(--table-header-bg) !important;
  border-radius: 18px !important;
}

.ant-menu-item:hover {
  /* background-color: #bfd8eb !important; */
  border-radius: 18px !important;
}
.admin-menu-list .ant-menu-submenu-title {
  border-radius: 18px !important;
}
.admin-menu-list .ant-menu-light .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-15rg2km).ant-menu-light
  > .ant-menu
  .ant-menu-item-selected {
  color: #ebeff4 !important;
}
.pro-sidebar .pro-menu .pro-menu-item {
  margin-top: 10px !important;
}
.pro-sidebar-layout ul {
  padding: 10px !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background-color: white !important;
}
.admin-menu-list {
  background-color: transparent !important;
}
.pro-sidebar-content {
  background-color: transparent !important;
}
.admin-menu-list .ant-menu-item > a {
  color: white !important;
}
.ant-menu-item a{
    color: white !important;
}