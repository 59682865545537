table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 1em;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  font-family: "Inter";
}
.table body {
  border-radius: 0px 0px 10px 10px;
  background: #ffffff;
}
.table th {
  padding: 12px 15px;
  text-align: start;
}                           
.column-width {
  width: 200px;
}
.table-header,
table > thead {
  background: linear-gradient(99.9deg, #381d6d, #aa2dae);
  color: #fff;
  border-radius: 25px;
}

table > thead > tr > th:first-child {
  color: #fff;
  /* border-radius: 20%; */
  /* border-top-left-radius: 20px; */
  /* border-bottom-left-radius: 20px; */
}
table > thead > tr > th:last-child {
  color: #fff;
  /* border-radius: 20%; */
  /* background: linear-gradient(99.9deg, #381d6d, #aa2dae) !important; */
  /* border-top-right-radius: 20px; 
  border-bottom-right-radius: 20px; */
}
.table-border-bottom {
  border: 1px solid #e0e0e0;
  padding: 12px 15px;
  /* border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px; */
  border-width: 0 0 1px;
  text-align: start;
  /* padding: 20px; */
}
.space-add > td {
  padding: 10px 0px 15px 25px;
  font-family: Inter;
}
/* .table tbody tr:nth-child(even){
    background-color: #f5f5f5;
} */

.table-header > tr > th {
  padding: 10px 0px 15px 25px;
  /* border-radius: 30px; */
}
table > thead > tr > th {
  padding: 10px 0px 15px 25px;
}
table > tbody > tr > td {
  padding: 10px 0px 15px 25px;
}
.radius-right {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.radius-left {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.tender_list {
  width: 50px;
}
.sub-header {
  padding: 0px !important;
  font-weight: 400 !important;
  color: black !important;
}
.sub-header-box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 600px;
  justify-content: space-around;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
  margin-left: 10px;
}
.second-header {
  background-color: #f2f2f2 !important;
  z-index: 0;
  margin-top: -20px;
}
.second-header-title {
  margin-left: 14px;
}
.third-header-title {
  margin-left: 3px;
}
.z-apply {
  z-index: 1;
}
.responsive_table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 1em;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
}
.responsive_table body {
  border-radius: 0px 0px 10px 10px;
  background: #ffffff;
}
.responsive_table th {
  padding: 12px 7px;
  text-align: start;
}

.responsive_table-header {
  padding: 10px 0px 15px 25px;
  background: linear-gradient(99.9deg, #381d6d, #aa2dae);
  color: #fff;
  border-radius: 20%;
}
.space > td {
  padding: 9px 2px 16px 10px;
}
.email-width {
  width: 300px !important;
}
.contact-table {
  box-shadow: none !important;
}
.contact-table body {
  border-radius: 0px 0px 10px 10px;
  background: #ffffff;
}
.contact-table th {
  padding: 12px 15px;
  text-align: start;
  min-height: 300px;
}
.column-width2 {
  padding-right: 15px !important;
}


